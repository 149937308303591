import dayjs, { type Dayjs } from 'dayjs';
import * as z from 'zod';
import { ModelTypeWithEvent } from '../@types';
import {
  BONUS_PRODUCT_TYPE_OPTIONS,
  BONUS_STATUS_OPTIONS_CUSTOM,
  BONUS_STATUS_OPTIONS_ONGOING,
  BONUS_TIMEFRAME_OPTIONS,
  BONUS_VALIDITY_PERIOD_OPTIONS,
  CURRENCY_ENUM,
  CUSTOMER_TASK_MESSAGE_TYPES,
  GENDER_ENUM,
  MODEL_TYPES_WITH_EVENT,
} from '../constants';
import { generateZodEnumIds } from '../utils';
import {
  condititionValueValidator,
  isEighteenOrOlder,
  itemEmailValidator,
  itemIdValidator,
  itemInformationValidator,
  itemNameValidator,
  itemPositionValidator,
  itemShortNameValidator,
  itemStringValidator,
  nonEmptyArray,
  nullTransformer,
  phoneValidationPattern,
  undefinedTransformer,
  validateRequiredNumber,
} from './helpers';

export const sportFormSchema = z.object({
  name: itemNameValidator(),
  shortName: itemShortNameValidator(),
  position: itemPositionValidator,
  isActive: z.boolean(),
  icon: z.string(),
  // array of strings, at least one string is required
  eventPartIds: z.array(z.string()).nonempty({ message: 'At least one event part is required.' }),
});

export type SportFormData = z.infer<typeof sportFormSchema>;

export const filterTotalPaymentsSchema = z.object({
  type: z.string({ invalid_type_error: 'Role is required' }),
  managerIds: z.array(z.string()).optional(),
  agentIds: z.array(z.string()).optional(),
  playerIds: z.array(z.string()).optional(),
});

export type FilterTotalPaymentsData = z.infer<typeof filterTotalPaymentsSchema>;

export const competitionFormSchema = z.object({
  sportId: itemIdValidator('Sport'),
  name: itemNameValidator(),
  shortName: itemShortNameValidator(),
  position: itemPositionValidator,
  isActive: z.boolean(),
});

export type CompetitionFormData = z.infer<typeof competitionFormSchema>;

export const tournamentFormSchema = z.object({
  sportId: itemIdValidator('Sport'),
  competitionId: itemIdValidator('Competition'),
  parentId: z.string().nullable(),
  name: itemNameValidator(50),
  shortName: itemShortNameValidator(25),
  position: itemPositionValidator,
  isActive: z.boolean(),
  topLeague: z.boolean(),
  topOffer: z.boolean(),
  topOfferPosition: itemPositionValidator,
  featured: z.boolean(),
  information: itemInformationValidator,
});

export type TournamentFormData = z.infer<typeof tournamentFormSchema>;

export const participantFormSchema = z.object({
  sportId: itemIdValidator('Sport'),
  competitionIds: z.array(z.string()).nonempty({ message: 'At least one competition is required.' }),
  tournamentIds: z.array(z.string()).nonempty({ message: 'At least one tournament is required.' }),
  name: itemNameValidator(),
  shortName: itemShortNameValidator(),
  isActive: z.boolean(),
});

export type ParticipantFormData = {
  sportId: string;
  competitionIds: string[];
  tournamentIds: string[];
  name: string;
  shortName: string | null;
  isActive: boolean;
};

export const playerFormSchema = z.object({
  participantId: itemIdValidator('Participant'),
  firstName: itemStringValidator({ label: 'First name' }),
  lastName: itemStringValidator({ label: 'Last name' }),
  displayName: itemShortNameValidator(100),
  isActive: z.boolean(),
  sportId: itemIdValidator('Sport'),
  competitionId: itemIdValidator('Competition'),
  tournamentId: itemIdValidator('Tournament'),
});

export const editPlayerFormSchema = playerFormSchema.omit({ sportId: true, competitionId: true, tournamentId: true });

export type PlayerFormData = z.infer<typeof playerFormSchema>;

export const outcomeTypeFormSchema = z.object({
  marketTypeId: itemIdValidator('Market'),
  name: itemNameValidator(),
  shortName: itemShortNameValidator(),
  description: itemInformationValidator,
  position: itemPositionValidator,
  isActive: z.boolean(),
});

export type OutcomeTypeFormData = z.infer<typeof outcomeTypeFormSchema>;

const specialValueSchema = z.object({
  id: z.number(),
  modelType: z
    .enum([...MODEL_TYPES_WITH_EVENT])
    .nullable()
    .transform(nullTransformer),
});

export const marketTypeFormSchema = z.object({
  name: itemNameValidator(),
  shortName: itemShortNameValidator(),
  sportId: itemIdValidator('Sport'),
  description: itemInformationValidator,
  position: itemPositionValidator,
  isActive: z.boolean(),
  specialValueModelTypes: z.array(specialValueSchema),
  isSingleDisplayType: z
    .number()
    .nullable()
    // value can set to null, but this will trigger validation error on form submit
    .refine((val) => val !== null, { message: 'Display type is required.' }),
  isCollapsedMode: z.boolean(),
  sportEventPartId: z.string().nullable(),
  usedFor: z.array(z.enum(['in_play', 'pre_match'])).nonempty({ message: 'At least one value is required.' }),
  type: z.enum(['match', 'outright', 'match_and_outright'], {
    required_error: 'Type is required.',
    invalid_type_error: 'Type is required.',
  }),
});

export type MarketTypeFormData = Omit<z.infer<typeof marketTypeFormSchema>, 'specialValueModelTypes'> & {
  specialValueModelTypes: { id: number; modelType: ModelTypeWithEvent | null }[];
};

export type MarketGroupsFormData = {
  name: string;
  usedFor: string;
  displayType: string;
  marketTypeIds: string[];
  isActive: boolean;
  sportId: string;
  id: string;
};

export const marketGroupsSchema = z.object({
  name: itemStringValidator({ label: 'Name', max: 15 }).refine((value) => /^[a-zA-Z0-9]*$/.test(value || ''), {
    message: 'Only letters and numbers are allowed in the name.',
  }),
  sportId: itemIdValidator('Sport'),
  marketTypeIds: z
    .array(z.string({ required_error: `Market is required.` }))
    .nonempty({ message: 'At least one market is required.' }),
  displayType: z
    .string({ invalid_type_error: 'Display type is required.' })
    .min(1, { message: 'Display type is required.' }),
  isActive: z.boolean(),
  usedFor: z
    .string({
      invalid_type_error: 'Used for is required.',
    })
    .min(1, { message: 'Used for is required.' }),
});

export const eventFormSchema = z.object({
  name: itemNameValidator(100),
  sportId: itemIdValidator('Sport'),
  competitionId: itemIdValidator('Competition'),
  tournamentId: itemIdValidator('Tournament'),
  participantIds: z
    .array(z.string({ required_error: `Participant is required.` }))
    .nonempty({ message: 'At least one participant is required.' }),
  startDate: z.instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required.' }),
  information: itemInformationValidator,
  featured: z.boolean(),
});

export const createEventFormSchema = eventFormSchema.omit({ competitionId: true, sportId: true, name: true });
export const editEventFormSchema = eventFormSchema.pick({
  name: true,
  startDate: true,
  information: true,
  featured: true,
});

export type EditEventFormData = z.infer<typeof editEventFormSchema>;
export type EventFormData = {
  name: string;
  sportId: string;
  competitionId: string;
  tournamentId: string;
  participantIds: string[];
  startDate: Dayjs;
  information: string | null;
  featured: boolean;
};

export const outrightFormSchema = z.object({
  name: itemNameValidator(100),
  sportId: itemIdValidator('Sport'),
  competitionId: itemIdValidator('Competition'),
  tournamentId: itemIdValidator('Tournament'),
  startDate: z.instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required.' }),
  information: itemInformationValidator,
});

export const createOutrightFormSchema = outrightFormSchema.omit({ competitionId: true, sportId: true });
export const editOutrightFormSchema = outrightFormSchema.pick({
  name: true,
  startDate: true,
  information: true,
});

export type EditOutrightFormData = z.infer<typeof editOutrightFormSchema>;
export type OutrightFormData = z.infer<typeof outrightFormSchema>;

const baseCustomerSchema = (transformer: (item: string | null) => string | null | undefined) =>
  z.object({
    firstName: itemStringValidator({ label: 'First name', min: 2, max: 255 }),
    lastName: itemStringValidator({ label: 'Last name', min: 2, max: 255 }),
    email: z
      .union([z.literal(''), itemEmailValidator])
      .nullable()
      .transform(undefinedTransformer),
    dateOfBirth: z
      .custom<Dayjs>(
        (date) => {
          if (!dayjs.isDayjs(date)) {
            return false;
          }
          return isEighteenOrOlder(date);
        },
        { message: 'Must be at least 18 years old.' }
      )
      .nullish(),
    phone: z
      .union([z.literal(''), z.string().regex(phoneValidationPattern, { message: 'Invalid phone number.' })])
      .nullish()
      .transform(undefinedTransformer),
    username: itemStringValidator({ label: 'Username', min: 2, max: 255 }),
    password: itemStringValidator({ label: 'Password' }),
    confirmPassword: itemStringValidator({ label: 'Confirm password' }),
    agentId: itemStringValidator({ label: 'Agent' }),
    country: itemStringValidator({ label: 'Country', required: false }).nullable().transform(undefinedTransformer),
    region: itemStringValidator({ label: 'Region', required: false, transformer: transformer }).nullish(),
    isActive: z.boolean(),
    address: itemStringValidator({ label: 'Address', required: false, transformer: transformer }).nullish(),
    zip: itemStringValidator({
      label: 'Zip Code',
      required: false,
      max: 25,
      transformer: transformer,
    }).nullish(),
    city: itemStringValidator({ label: 'City', required: false, transformer: transformer }).nullish(),
    gender: z
      .enum([...GENDER_ENUM, ''])
      .nullish()
      .transform(undefinedTransformer),
    nationalId: itemStringValidator({
      label: 'National ID',
      required: false,
      transformer: transformer,
    }).nullish(),
    currency: z
      .enum([...CURRENCY_ENUM, ''])
      .nullish()
      .transform(undefinedTransformer),
    language: itemStringValidator({ label: 'Language', required: false, transformer: transformer }).nullish(),
  });

export const customerSchema = baseCustomerSchema(undefinedTransformer)
  .extend({
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match.",
    path: ['confirmPassword'],
  });

export const editCustomerSchema = baseCustomerSchema(nullTransformer)
  .omit({
    username: true,
    agentId: true,
  })
  .extend({
    password: z.string().optional(),
    confirmPassword: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match.",
    path: ['confirmPassword'],
  })
  .refine((data) => !data.password || data.password.length >= 8, {
    message: 'String must contain at least 8 character(s)',
    path: ['password'],
  })
  .refine((data) => !data.confirmPassword || data.confirmPassword.length >= 8, {
    message: 'String must contain at least 8 character(s)',
    path: ['confirmPassword'],
  })
  .transform((data) => {
    const { password, confirmPassword, ...rest } = data;
    return password ? { password, ...rest } : rest;
  });

export type CustomerFormData = z.infer<typeof customerSchema>;

export const crmTaskSchema = z.object({
  scheduledTime: z.instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required.' }),
  type: z.enum(CUSTOMER_TASK_MESSAGE_TYPES, { required_error: 'Message type is required.' }).transform(nullTransformer),
  comment: itemStringValidator({ label: 'Description', max: 300 }),
});

export type CRMTaskFormData = z.infer<typeof crmTaskSchema>;

export const addNewSpecialValueSchema = z.object({
  values: z.array(z.object({ id: z.string().min(1, { message: 'Value is required.' }) })),
  isActive: z.boolean(),
  participantId: itemIdValidator('Participant'),
  playerId: itemIdValidator('Player'),
});

export type AddNewSpecialValueFormData = z.infer<typeof addNewSpecialValueSchema>;

export const editableTableCellSchema = (isSpecialValueInput: boolean) =>
  z.object({
    value: isSpecialValueInput ? z.coerce.number() : z.coerce.number().min(1),
  });

export const resultsSchema = z.object({
  scores: z.array(
    z.object({
      eventPartName: z.string(),
      eventPartScoreId: z.string(),
      valueHome: z.number(),
      valueAway: z.number(),
    })
  ),
});

export type ResultsFormData = z.infer<typeof resultsSchema>;

export const conditionTypeSchema = z.object({
  name: itemNameValidator(300),
  language: z.enum(['en', 'sr']),
  restrictionMsgAdmin: itemStringValidator({
    label: 'Restriction Message Admin',
    max: 300,
    required: false,
  }).nullish(),
  verificationMsgAdmin: itemStringValidator({
    label: 'Verification Message Admin',
    max: 300,
    required: false,
  }).nullish(),
  restrictionMsgClient: itemStringValidator({
    label: 'Restriction Message Client',
    max: 300,
    required: false,
  }).nullish(),
  verificationMsgClient: itemStringValidator({
    label: 'Verification Message Client',
    max: 300,
    required: false,
  }).nullish(),
  description: itemStringValidator({ label: 'Description', max: 300, required: false }),
});

export type ConditionTypesFormData = z.infer<typeof conditionTypeSchema>;

export const conditionGroupSchema = z.object({
  name: itemNameValidator(300),
  type: z.enum(['sport', 'competition', 'tournament', 'market_type', 'event', 'punter', 'ticket']),
  description: itemStringValidator({ label: 'Description', max: 300, required: false }).nullable(),
});

export type ConditionGroupsFormData = z.infer<typeof conditionGroupSchema>;

export const conditionTemplateSchema = z.object({
  conditionGroupId: itemIdValidator('Condition Group').nullable(),
  conditions: z.array(
    z.object({
      conditionTypeId: itemIdValidator('Condition Type'),
      restrictionValue: condititionValueValidator,
      verificationValue: condititionValueValidator,
    })
  ),
});

export type ConditionTemplateFormData = z.infer<typeof conditionTemplateSchema>;

export const conditionSchema = z.object({
  modelId: itemIdValidator('Model'),
  conditionGroupId: itemIdValidator('Condition Group').nullable(),
  type: z.enum(['sport', 'competition', 'tournament', 'market_type', 'event', 'punter', 'ticket']),
  conditions: z.array(
    z.object({
      conditionTypeId: itemIdValidator('Condition Type'),
      restrictionValue: condititionValueValidator,
      verificationValue: condititionValueValidator,
    })
  ),
});

export type ConditionFormData = z.infer<typeof conditionSchema> & { product: string | null };

export const ticketReofferSchema = (maxStake: number) =>
  z.object({
    totalStakeAmount: z.coerce.number().max(maxStake),
    odds: z.record(z.string(), z.string()),
    specialValues: z.record(
      z.string(),
      z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          odds: z.string(),
        })
      )
    ),
  });

export const liabilitiesFormSchema = z.object({
  liabilities: z.array(
    z.object({
      name: z.string().min(1, { message: 'Name is required.' }),
      value: z.number(),
    })
  ),
});

export type LiabilitiesFormData = z.infer<typeof liabilitiesFormSchema>;

export const riskFactorsFormSchema = z.object({
  riskFactors: z.array(
    z.object({
      name: z.string().min(1, { message: 'Name is required.' }),
      value: z.number(),
    })
  ),
});

export type RiskFactorsFormData = z.infer<typeof riskFactorsFormSchema>;

export const riskManagementFormSchema = z.object({
  items: z.array(
    z.object({
      name: z.string().min(1, { message: 'Name is required.' }),
      value: z.string().min(1, { message: 'Value is required.' }),
    })
  ),
});

export type RiskManagementFormData = z.infer<typeof riskManagementFormSchema>;

export const commissionSchema = z.object({
  playerCommissionType: z.string(),
  playerCommissionPercentage: z.string(),
  partnerCommissionType: z.string(),
  partnerCommissionPercentage: z.string(),
  picks: z.object({
    '1': z.coerce.number().min(0).max(100),
    '2': z.coerce.number().min(0).max(100),
    '3': z.coerce.number().min(0).max(100),
    '4-7': z.coerce.number().min(0).max(100),
    '8-9': z.coerce.number().min(0).max(100),
    '10+': z.coerce.number().min(0).max(100),
  }),
});

export const crmUserSchema = z.object({
  firstName: z.array(z.string()),
  lastName: z.array(z.string()),
  fullName: z.array(z.string()),
  isActive: z.boolean(),
  country: z.array(z.string()),
  createdAt: z.object({
    from: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date'),
    to: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date'),
  }),
  phone: z.array(z.string()),
  email: z.array(z.string()),
  zip: z.array(z.string()),
  city: z.array(z.string()),
  region: z.array(z.string()),
  language: z.array(z.string()),
  age: z.object({
    from: z.number({ required_error: 'Range is required.', invalid_type_error: 'Range is required.' }),
    to: z.number({ required_error: 'Range is required.', invalid_type_error: 'Range is required.' }),
  }),
  roleTypes: z.array(z.string()),
});

export const handleCreditsSchema = z.object({
  walletType: itemStringValidator({ label: 'Wallet' }),
  transactionType: itemStringValidator({ label: 'Transaction' }),
  amount: z.coerce.number().positive({ message: 'Amount must be greater than 0' }),
  description: z.string().optional().transform(undefinedTransformer),
});

export const messagesFormSchema = z.object({
  group: z.string({ required_error: 'Field is required.' }).nullable(),
  topic: z.string().max(30, { message: 'Field must be 30 characters or fewer' }),
  text: z.string({ required_error: 'Field is required' }),
  partnerIds: z.array(z.string()).nullable(),
  playerIds: z.array(z.string()),
});

export type CreateMessageFormData = z.infer<typeof messagesFormSchema>;

export const jackpotFormSchema = z.object({
  name: itemNameValidator(300),
  product: itemStringValidator({ label: 'Product' }),
  isActive: z.boolean(),
  selectedStakeRangeIds: nonEmptyArray('Pay in amount is required.'),
  payInAmountRanges: z.array(
    z.object({
      id: z.string(),
      from: z.coerce.number().min(0.1, { message: 'Must be higher than 0.1' }),
      to: z.coerce
        .number()
        .nullish()
        .transform((val) => (val ? val : undefined)),
      contribution: z.coerce.number().positive({ message: 'Must be a positive number.' }),
    })
  ),
  winningTickets: nonEmptyArray('Winning ticket range is required.'),
  winningLow: validateRequiredNumber('Winning range'),
  winningHigh: validateRequiredNumber('Winning range'),
  description: itemInformationValidator,
  icon: z.string(),
});

export const editJackpotFormSchema = jackpotFormSchema.pick({
  name: true,
  isActive: true,
  description: true,
  icon: true,
});

export type JackpotFormData = z.infer<typeof jackpotFormSchema>;

export const SportEventPartsSchema = z.object({
  sportEventParts: z.array(
    z.object({
      id: z.string(),
      eventPartName: z.string(),
      shortName: z.string().nullable(),
      isActive: z.boolean(),
      position: itemPositionValidator,
    })
  ),
});

export type SportEventPartsFormData = z.infer<typeof SportEventPartsSchema>;

export const marketMarginSchema = z.object({
  marketMargin: z.coerce.number().min(1, 'Must be between 1 and 100').max(100, 'Must be between 1 and 100'),
});

export type MarketMarginFormData = z.infer<typeof marketMarginSchema>;

export const bonusFilterSchema = z.object({
  product: z.enum(generateZodEnumIds(BONUS_PRODUCT_TYPE_OPTIONS)),
  validityPeriod: z.enum(generateZodEnumIds(BONUS_VALIDITY_PERIOD_OPTIONS)),
  fromTimestamp: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date').nullish(),
  toTimestamp: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date').nullish(),
  schedule: z.enum(generateZodEnumIds(BONUS_TIMEFRAME_OPTIONS)),
});

export type BonusFilterFormData = z.infer<typeof bonusFilterSchema>;

export const bonusFormSchema = z.object({
  name: itemNameValidator(),
  eligibility: z.string(),
  status: z.enum([
    ...generateZodEnumIds(BONUS_STATUS_OPTIONS_CUSTOM),
    ...generateZodEnumIds(BONUS_STATUS_OPTIONS_ONGOING),
  ]),
  refundPercentage: validateRequiredNumber('Refund percentage'),
  minimumLostAmount: z.number().nullish(),
  maximumRefundAmount: z.number().nullish(),
  userIds: z.array(z.string()),
  description: itemInformationValidator,
  ...bonusFilterSchema.shape,
});

export type BonusFormData = z.infer<typeof bonusFormSchema>;
