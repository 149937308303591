import React from 'react';
import { GridToolbarExportContainer, GridCsvExportMenuItem, GridPrintExportMenuItem } from '@mui/x-data-grid';
import ExcelExportMenuOption from '../atoms/ExcelExportMenuOption';
import { exportStyles } from '../../utils/export';
import StyledIcon from '../atoms/StyledIcon';
import { GetApp } from '@mui/icons-material';

const CustomToolbar = (showSearch: boolean, showCustomizeTable: boolean) => {
  return (
    <GridToolbarExportContainer
      variant='text'
      sx={exportStyles(showSearch, showCustomizeTable)}
      startIcon={<StyledIcon Icon={GetApp} />}
    >
      <GridCsvExportMenuItem />
      <GridPrintExportMenuItem
        options={{
          disableToolbarButton: true,
        }}
      />
      <ExcelExportMenuOption />
    </GridToolbarExportContainer>
  );
};

export default CustomToolbar;
