import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import { Fragment, useMemo } from 'react';
import { Permission, StyleObj } from '../../@types';
import { Config, NAVIGATION_ITEMS_LIST, NavigationObjectType } from '../../config';
import usePermissions from '../../hooks/usePermissions';
import NavigationItem from './NavigationItem';

export const drawerWidth = 240;

type NavigationProps = {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...styles.openedMixin?.(theme),
    '& .MuiDrawer-paper': styles.openedMixin?.(theme),
  }),
  ...(!open && {
    ...styles.closedMixin?.(theme),
    '& .MuiDrawer-paper': styles.closedMixin?.(theme),
  }),
}));

const styles: StyleObj & {
  openedMixin?: (theme: Theme) => CSSObject;
  closedMixin?: (theme: Theme) => CSSObject;
} = {
  openedMixin: (theme) => ({
    backgroundColor: theme.palette.background.light,
    borderRight: 'none',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
  }),
  closedMixin: (theme): CSSObject => ({
    backgroundColor: theme.palette.background.light,
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(10)} + 1px)`,
    },
  }),

  navItem: { color: 'primary.main', px: 3, pb: 1, pt: 1 },
  list: { py: 0 },
  divider: { backgroundColor: 'primary.main', my: 1, mx: 3 },
  rightIcon: { mr: 1.5 },
};

const filterSectionByPermission = (
  items: NavigationObjectType[],
  hasPermission: (permission: Permission) => boolean
) => {
  return items.filter((item) => {
    if (!!item.children?.length) {
      item.children = filterSectionByPermission(item.children, hasPermission);
    }

    return !item.permission || hasPermission(item.permission);
  });
};

const filterConfigByPermission = (config: Config, hasPermission: (permission: Permission) => boolean): Config => {
  const filteredConfig = {} as Config;

  for (const key in config) {
    const section = config[key as keyof Config];
    const filteredItems = filterSectionByPermission(section, hasPermission);

    if (filteredItems.length > 0) {
      filteredConfig[key as keyof Config] = filteredItems;
    }
  }

  return filteredConfig;
};

const DrawerNavigation = ({ open, handleDrawerClose, handleDrawerOpen }: NavigationProps) => {
  const { hasPermission } = usePermissions();

  const filteredConfig = useMemo(() => filterConfigByPermission(NAVIGATION_ITEMS_LIST, hasPermission), [hasPermission]);

  const renderNavigationSection = (sectionName: string, items: NavigationObjectType[], isLastSection: boolean) => {
    return (
      <Fragment key={sectionName}>
        {open && (
          <Typography variant='subtitle1' sx={styles.navItem}>
            {sectionName}
          </Typography>
        )}
        <List sx={styles.list}>
          {items.map((item) => (
            <NavigationItem open={open} item={item} key={item.name} handleDrawerClose={handleDrawerClose} />
          ))}
        </List>
        {!isLastSection && <Divider sx={styles.divider} />}
      </Fragment>
    );
  };

  const renderNavigationDrawerContent = () => {
    const sectionKeys = Object.keys(filteredConfig);

    return sectionKeys.map((key, index) => {
      const isLastSection = index === sectionKeys.length - 1;
      const sectionItems = filteredConfig[key as keyof Config];

      return renderNavigationSection(key, sectionItems, isLastSection);
    });
  };

  return (
    <StyledDrawer variant='permanent' open={open}>
      <DrawerHeader>
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} color='primary' disableRipple>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon sx={styles.rightIcon} />}
        </IconButton>
      </DrawerHeader>
      {renderNavigationDrawerContent()}
    </StyledDrawer>
  );
};

export default DrawerNavigation;
