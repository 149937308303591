import { SvgIconComponent } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import StyledIcon from './StyledIcon';

type TableActionButtonProps = ButtonProps & {
  Icon: SvgIconComponent;
};
const TableActionButton = ({ Icon, children, ...buttonProps }: TableActionButtonProps) => {
  return (
    <Button sx={{ pl: 0 }} variant='text' startIcon={<StyledIcon Icon={Icon} />} {...buttonProps}>
      {children}
    </Button>
  );
};

export default TableActionButton;
