import dayjs, { Dayjs } from 'dayjs';
import * as z from 'zod';

type ItemStringValidationProps = {
  label: string;
  max: number;
  min: number;
  required: boolean;
  transformer: (item: string | null) => string | null | undefined;
};

export const phoneValidationPattern = /^\+\d{1,3}\d{6,14}$/;

export const nullTransformer = (item: string | null) => (item?.trim() === '' ? null : item);
export const undefinedTransformer = (item: string | null | undefined) =>
  item?.trim() === '' || item === null ? undefined : item;

export const itemStringValidator = (options: Partial<ItemStringValidationProps>) => {
  const { label, max = 50, min, required = true, transformer = nullTransformer } = options;

  const minLength = min !== undefined ? min : required ? 1 : 0;

  return z
    .string({ required_error: `${label} is required.`, invalid_type_error: `${label} is required.` })
    .trim()
    .max(max, { message: `Must be ${max} characters or fewer.` })
    .refine((val) => val.length >= minLength, {
      message: min ? `${label ?? 'Value'} must be at least ${min} characters.` : `${label} is required.`,
    })
    .transform(transformer);
};

export const itemNameValidator = (max = 100) => itemStringValidator({ label: 'Name', max });

export const itemShortNameValidator = (max = 50) =>
  z
    .string()
    .max(max, { message: `Must be ${max} characters or fewer.` })
    .trim()
    .nullable()
    .transform(nullTransformer);

export const itemPositionValidator = z.number().nullable();

export const itemInformationValidator = z
  .string()
  .max(300, { message: 'Must be 300 characters or fewer.' })
  .nullable()
  .transform(nullTransformer);

export const itemIdValidator = (label: string) => z.string({ required_error: `${label} is required.` });

export const itemEmailValidator = z
  .string()
  .email({ message: 'Invalid email.' })
  .max(100, { message: 'Must be 100 characters or fewer.' });

export const condititionValueValidator = z.union([z.string(), z.number(), z.null()]).transform((v) => {
  if (!v) return null;
  return typeof v === 'number' ? v : parseFloat(v);
});

export const isEighteenOrOlder = (date: Dayjs) => {
  return date.isBefore(dayjs().subtract(18, 'years'));
};

export const nonEmptyArray = (message: string) =>
  z
    .string()
    .array()
    .refine((arr) => arr.length > 0, { message });

export const validateRequiredNumber = (fieldName: string) =>
  z.number({
    required_error: `${fieldName} is required.`,
    invalid_type_error: `${fieldName} is required.`,
  });
